function fadeIn(el, timeout, display, afterFunc = false) {
    el.style.opacity = 0;
    el.style.display = display || 'block';
    el.style.transition = `opacity ${timeout}ms`;
    setTimeout(() => {
        el.style.opacity = 1;
        setTimeout(function(){
            if(afterFunc) {
                afterFunc()
            }

            setTimeout(function () {
                el.removeAttribute('style')
            },10)
        }, timeout)
    }, 10);
}
function fadeOut(el, timeout, afterFunc = false) {
    el.style.opacity = 1;
    el.style.transition = `opacity ${timeout}ms`;
    el.style.opacity = 0;

    setTimeout(() => {
        el.style.display = 'none';

        if(afterFunc){
            afterFunc()
        }
        setTimeout(function () {
            el.removeAttribute('style')
            el.style.display = 'none';
        },10)
    }, timeout);
}

function anchors() {
    $('a[href*="#"]').on('click', function () {
        let href = $(this).attr('href');
        let block = $('#'+href.split('#').pop());
        let headerHeigth = $('.header').height();

        if(block.length) {
            $('html,body').animate({
                scrollTop: block.offset().top - headerHeigth - 50  + "px"
            }, {
                duration: 1E3
            });
        }
    });
}

const only_num = /^[0-9]+$/;
const only_letters = /^[a-zA-Zа-яієїґА-ЯІЄЇҐ\s\-]*$/;
const only_num_replace = /[^0-9.]/g;
const email_reg = /^(([^<>()\[\]\\.,;:\s@"]{2,62}(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-аА-яЯ\-0-9]+\.)+[a-zA-Z-аА-яЯ]{2,62}))$/;

const validationRules = {
    'name': {
        'rules': {
            regex: only_letters
        }
    },
    'email': {
        'rules': {
            regex: email_reg
        }
    },
    'numeric': {
        'rules': {
            regex: only_num
        }
    },
    'password': {
        'rules': {
            password: true
        }
    },
    'password_repeat': {
        'rules': {
            password_repeat: true
        }
    }
}



function selectAll(selector, container = false) {
    return Array.from(!container ? document.querySelectorAll(selector) : container.querySelectorAll(selector));
}
function validate(form, newOpts = {}) {
    let defaultOpts = {
        submitFunction: null,
        highlightFunction: null,
        unhighlightFunction: null,
        checkOnInput: false,
        checkOnInputAfterSubmit: true,
        checkOnFocusOut: true,
        errorClass: 'is-error'
    }
    let opts = {
        ...defaultOpts,
        ...newOpts
    }
    if (typeof form === 'string')
        form = document.querySelector(form)

    let _this = {
        isValid: true,
        allInputs: selectAll('input:not([type="hidden"]), .output_value, select, textarea', form),
        formSubmitted: false,
        init: function(){
            form.setAttribute('novalidate', 'novalidate')
            form.setAttribute('data-js-validation', 'novalidate')
            form.addEventListener('submit', function (e) {
                e.preventDefault();
                _this.validate()
                _this.formSubmitted = true;
            })

            form.valid = function (addErrors = false) {
                return _this.validate(false, addErrors)
            }

            _this.allInputs.map(function (input) {
                let thisInputMethods = [];
                let dataValidation = input.getAttribute('data-validation')

                if(input.hasAttribute('required')){
                    thisInputMethods.push({
                        callback: methods['required'],
                        errorMessage: (validationErrors[dataValidation] ? validationErrors[dataValidation]['required'] : false) || validationErrors['required'] || 'This field is required'
                    })
                }
                if(input.hasAttribute('data-tel-mask')){
                    thisInputMethods.push({
                        callback: methods['tel_mask'],
                        errorMessage: ''
                    })
                }
                if(input.getAttribute('type') === 'email'){
                    thisInputMethods.push({
                        callback: methods['regex'],
                        passedValue: email_reg,
                        errorMessage: validationErrors['email']['regex'] || validationErrors['invalid'] || 'This field is invalid'
                    })
                }
                if(dataValidation){
                    let thisValidation = validationRules[input.getAttribute('data-validation')]

                    if(thisValidation)
                        thisValidation = thisValidation['rules']

                    if(thisValidation) {
                        let existingMethod = false;

                        Object.keys(thisValidation).forEach(methodName => {
                            let thisValidationValue = thisValidation[methodName];

                            if (methods[methodName])
                                existingMethod = {
                                    callback: methods[methodName],
                                    passedValue: thisValidationValue,
                                    errorMessage: (validationErrors[dataValidation] ? validationErrors[dataValidation][methodName] : false) || validationErrors['invalid'] || 'This field is invalid'
                                }
                        });

                        if (existingMethod)
                            thisInputMethods.push(existingMethod)
                    }
                }

                function isInputRequired(removeIt = false) {
                    let thisInputActualMethods = input['validationMethods']
                    let hasRequired = false;

                    thisInputActualMethods.map(function (method) {
                        if(method.callback.name === 'required') {
                            hasRequired = true;
                            if(removeIt)
                                thisInputActualMethods.splice(thisInputActualMethods.indexOf(method), 1)
                        }
                    })
                    return hasRequired;
                }
                function setRequired() {
                    let thisInputActualMethods = input['validationMethods']

                    if(isInputRequired()) return;

                    thisInputActualMethods.push({
                        callback: methods['required'],
                        errorMessage: (validationErrors[dataValidation] ? validationErrors[dataValidation]['required'] : false) || validationErrors['required'] || 'This field is required'
                    })

                    input['validationMethods'] = thisInputMethods
                }
                function removeRequired() {
                    isInputRequired(true)
                }

                input['setRequired'] = setRequired
                input['removeRequired'] = removeRequired
                input['isRequired'] = isInputRequired
                input['validationMethods'] = thisInputMethods
                input['had_input'] = false;
                input['had_focusout'] = false;

                input['isValid'] = function () {
                    return _this.valid(input)
                }

                input.addEventListener('input', function () {
                    this['had_input'] = true;

                    if(opts.checkOnFocusOut && input['had_focusout']) {
                        _this.valid(this)
                        return;
                    }
                    if(opts.checkOnInput) {
                        _this.valid(this)
                        return;
                    }
                    if(opts.checkOnInputAfterSubmit && _this.formSubmitted){
                        _this.valid(this)
                    }
                })
                if(!opts.checkOnInput && opts.checkOnFocusOut)
                    input.addEventListener('focusout', function () {
                        this['had_focusout'] = true;
                        if(!this['had_focusout'] || !this['had_input']) return;
                        _this.valid(this)
                    })
            })

            if(opts['rules']){
                Object.keys(opts['rules']).forEach(function (rule) {
                    let input = document.querySelector('[name="'+rule+'"]');
                    let thisRuleValue = opts['rules'][rule];
                    let thisInputMethods = input['validationMethods'] || []

                    if(!input) return;

                    if(thisRuleValue['laravelRequired'])
                        thisRuleValue = 'required'

                    let thisRuleMessage = (validationErrors[thisRuleValue] ? validationErrors[thisRuleValue] : false) || validationErrors[thisRuleValue] || 'Це поле обов\'язкове'

                    if(opts['messages'] && opts['messages'][rule] && (opts['messages'][rule][thisRuleValue] || opts['messages'][rule]['laravelRequired']))
                        thisRuleMessage = opts['messages'][rule][thisRuleValue] || opts['messages'][rule]['laravelRequired']

                    if(methods[thisRuleValue]){
                        thisInputMethods.push({
                            callback: methods[thisRuleValue],
                            errorMessage: thisRuleMessage
                        })

                        input['validationMethods'] = thisInputMethods
                    }
                })
            }
        },
        valid: function (input, addError = true) {
            let thisMethods = input['validationMethods']
            if(!thisMethods) return true;
            let isInputValid = true;

            thisMethods.forEach(function (thisMethod) {
                if(!isInputValid) return;
                let isThisValid = thisMethod['callback'](input.value, input, thisMethod['passedValue'])

                if(!isThisValid){
                    if(addError) {
                        _this.highlight(input)
                        _this.errorPlacement(thisMethod['errorMessage'], input)
                    }
                    _this.isValid = isInputValid = input['validity']['valid'] = false;
                }
            })

            if(isInputValid) {
                _this.unhighlight(input)
                _this.errorRemove(input)

                input['validity']['valid'] = true;
            }

            return isInputValid;
        },
        validate: function (submit = true, addError = true) {
            _this.isValid = true;

            _this.allInputs.map(function (input) {
                if(!_this.valid(input, addError)) {
                    _this.isValid = false
                }
            })

            if(_this.isValid && submit)
                _this.submitHandler()

            return _this.isValid;
        },
        highlight: function (element) {
            if (typeof opts.highlightFunction === 'function') {
                opts.highlightFunction(form, element);
                return;
            }
            let container = element.closest('label')

            element.classList.add('is-error')
            if(!container)
                container = element.closest('.form-group')
            if(container)
                container.classList.add(opts.errorClass);
        },
        unhighlight: function (element) {
            if (typeof opts.unhighlightFunction === 'function') {
                opts.unhighlightFunction(form, element);
                return;
            }
            let container = element.closest('label')

            element.classList.remove('is-error')
            if(!container)
                container = element.closest('.form-group')
            if(container)
                container.classList.remove(opts.errorClass);
        },
        errorPlacement: function (error, element) {
            if(!error) return;
            let container = element.closest('.input')
            if(!container) return;
            let errorEl = container.querySelector('.input__error')

            if(!errorEl) {
                errorEl = document.createElement('div')
                errorEl.classList.add('input__error')
                if(container.querySelector('.input__bottom'))
                    container.querySelector('.input__bottom').append(errorEl)
            }

            errorEl.innerHTML = error
        },
        errorRemove: function (element) {
            let container = element.closest('.input')

            if (!container) return;
            container = container.querySelector('.input__error')
            if (!container) return;

            container.innerHTML = ''
        },
        submitHandler: function () {
            if (typeof opts.submitFunction === 'function') {
                opts.submitFunction(form);
            } else {
                form.submit();
            }
        }
    }

    let methods = {
        "regex": function (value, element, regexp) {
            return value == '' || new RegExp(regexp).test(value);
        },
        "required": function (value, input) {
            return input.getAttribute('type') === 'checkbox' || input.getAttribute('type') === 'radio' ? input.checked : !!value;
        },
        "regexReplace": function (value, element, regexp) {
            element.value = element.value.replace(new RegExp(regexp), "");
            return true;
        },
        "password": function (value, element, regexp) {
            return (!element.hasAttribute('required') && !value) || value.length >= 8;
        },
        "password_repeat": function (value, element, regexp) {
            let password = element.closest('form').querySelector('[data-validation="password"]')
            return (!element.hasAttribute('required') && !value) || (value.length >= 8 && value === password.value);
        },
        "tel_mask": function (value, element, regexp) {
            if(typeof element['checkValidCallback'] !== 'undefined'){
                element.checkValidCallback()
            }
            return typeof element['telMask'] !== 'undefined' ? (element['telMask'].isValidNumber() || value === '') : true;
        },
    }

    if(!form.hasAttribute('data-js-validation'))
        _this.init()
}

function beforeForm(form){
    form.querySelector('[type="submit"]').setAttribute('disabled', 'disabled')
    form.classList.remove('ajax-error')
}
function resetForm(form){
    let successContainer = $(form).closest('.show-hide-container')
    let successBlockShow = successContainer.find('.show-on-success')
    let successBlockHide = successContainer.find('.hide-on-success')

    if(successContainer.length && successBlockShow.length){
        if(successBlockHide.length) {
            successBlockHide.fadeOut(300, function () {
                successBlockShow.fadeIn(300)
            })
        } else {
            successBlockShow.fadeIn(300)
        }
        if(!$('.fancybox__container').length)
            $("html, body").animate({scrollTop: 0}, "slow");
    }

    form.reset()
    form.querySelector('[type="submit"]').removeAttribute('disabled')

    selectAll('.input--file').map(function (input) {
        input.querySelector('[data-placeholder]').innerHTML = input.querySelector('[data-placeholder]').getAttribute('data-placeholder')
    })
}
function ajaxError(form, data) {
    form.querySelector('[type="submit"]').removeAttribute('disabled')
    if(data['responseJSON'] && data['responseJSON']['data'] && typeof data['responseJSON']['data'] === 'string') {
        $(form).find('.form__error').text(data['responseJSON']['data'])
        $(form).addClass('ajax-error')
    }
}




function blocks() {
    let methods = {

        '.promo': function(sections){

            $(document).on('click', '.promo__item', function () {
                let thisVideo = this.querySelector('video')
                let playBtn = this.querySelector('.btn--play')
                if(!thisVideo) return;
                if(!this.closest('.swiper-slide-active')) return;

                if (thisVideo.paused) {
                    thisVideo.play();
                    fadeOut(playBtn, 300)
                } else {
                    thisVideo.pause();
                    fadeIn(playBtn, 300)
                }
            })
        },
        '.visualization': function (sections) {
            sections.forEach(function(section){

                let tabs = $(section).find(".visualization__tabs [data-tab]");
                let tabsWrappers = section.querySelectorAll(".visualization__wrapper");


                tabsWrappers.forEach(function (tabWrapper) {
                    let active = false;
                    // First we'll have to set up our event listeners
                    // We want to watch for clicks on our scroller
                    tabWrapper.querySelector('.scroller').addEventListener('mousedown',function(){
                        active = true;
                        // Add our scrolling class so the scroller has full opacity while active
                        tabWrapper.querySelector('.scroller').classList.add('scrolling');
                    });
                    // We also want to watch the body for changes to the state,
                    // like moving around and releasing the click
                    // so let's set up our event listeners
                    document.body.addEventListener('mouseup',function(){
                        active = false;
                        tabWrapper.querySelector('.scroller').classList.remove('scrolling');
                    });
                    document.body.addEventListener('mouseleave',function(){
                        active = false;
                        tabWrapper.querySelector('.scroller').classList.remove('scrolling');
                    });

                    // Let's figure out where their mouse is at
                    document.body.addEventListener('mousemove',function(e){
                        if (!active) return;
                        // Their mouse is here...
                        let x = e.pageX;
                        // but we want it relative to our wrapper
                        x -= tabWrapper.getBoundingClientRect().left;
                        // Okay let's change our state
                        scrollIt(x);
                    });
                    // Let's figure out where their mouse is at
                    document.body.addEventListener('touchmove',function(e){
                        if (!active) return;
                        // Their mouse is here...
                        let x = e.pageX;
                        // but we want it relative to our wrapper
                        x -= tabWrapper.getBoundingClientRect().left;
                        // Okay let's change our state
                        scrollIt(x);
                    });
                    // Let's use this function
                    function scrollIt(x){
                        let widthScroller = +tabWrapper.querySelector('.scroller svg').clientWidth / 2;

                        let transform = Math.max(0,(Math.min(x,tabWrapper.offsetWidth)));
                        tabWrapper.querySelector('.after').style.width = transform+"px";
                        tabWrapper.querySelector('.scroller').style.left = transform - widthScroller +"px";

                    }
                    // Let's set our opening state based off the width,
                    // we want to show a bit of both images so the user can see what's going on

                    // And finally let's repeat the process for touch events
                    // first our middle scroller...
                    tabWrapper.querySelector('.scroller').addEventListener('touchstart',function(){
                        active = true;
                        tabWrapper.querySelector('.scroller').classList.add('scrolling');
                    });
                    document.body.addEventListener('touchend',function(){
                        active = false;
                        tabWrapper.querySelector('.scroller').classList.remove('scrolling');
                    });
                    document.body.addEventListener('touchcancel',function(){
                        active = false;
                        tabWrapper.querySelector('.scroller').classList.remove('scrolling');
                    });


                })

                tabs.eq(0).addClass('active')

                tabs.on('click', function () {
                    let thisBtn = $(this)
                    let elseBtn = tabs.not(thisBtn)
                    let thisTab = $(section).find('.visualization__picture [data-tab="' + thisBtn.attr('data-tab') + '"]');
                    let elseTab = $(section).find('.visualization__picture [data-tab]').not(thisTab)

                    thisBtn.addClass('active')
                    elseBtn.removeClass('active')

                    thisTab.addClass('active')
                    elseTab.removeClass('active')

                    elseTab.fadeOut(500, function () {
                        thisTab.fadeIn(500)
                    })
                })

            })
        },

        '.header__mobile': function (sections) {
            sections.forEach(function(section){
                let burger = section.querySelector(".burger");
                let menu = document.querySelector(".menu");
                let body = document.querySelector(".page__body");

                burger.addEventListener('click', function(){
                    menu.classList.toggle('is-active');
                    burger.classList.toggle('is-active');
                    body.classList.toggle('menu-open')
                })
            })
        },

        '.donate': function(sections){
            function onSubmit(form) {
                let formData = new FormData(form)
                let price = formData.getAll('price').filter(item=>item)

                $(form).find('[type="submit"]').attr('disabled', 'disabled')
                $.ajax({
                    url: '/wp-admin/admin-ajax.php',
                    type: 'POST',
                    data: {
                        action: 'donate_action',
                        price: price
                    },
                    success: function (data) {
                        $(form).find('[type="submit"]').removeAttr('disabled')

                        if(data['data'] && data['data']['redirect_url']) {
                            document.location.href = data['data']['redirect_url']
                        }
                    },
                    error: function(data) {
                        $(form).find('[type="submit"]').removeAttr('disabled')
                    }
                })
            }

            sections.forEach(function(section) {
                let form = section.querySelector('form')
                let textRadio = form.querySelectorAll('input.radio__btn:not([type="radio"])')
                let inputRadio = form.querySelectorAll('input[type="radio"]')

                $(textRadio).on('click', function () {
                    let thisName = $(this).attr('name')
                    let elseInputs = $(form).find('[name="'+thisName+'"]:checked')

                    $(this).addClass('is-selected')
                    elseInputs.prop('checked', false)
                    this.setRequired()
                })
                $(inputRadio).on('click', function () {
                    $(textRadio).removeClass('is-selected')
                    $(textRadio).val('')
                    textRadio.forEach(function (input) {
                        input.removeRequired()
                    })
                })

                validate(form, {submitFunction: onSubmit, checkOnInput: true})
            })

        },
        '.form': function(forms){
            function onSubmit(form) {
                let formData = new FormData(form)
                let btn = $(form).find('[type="submit"]')

                btn.attr('disabled', 'disabled')
                $.ajax({
                    url: '/wp-admin/admin-ajax.php',
                    method: 'post',
                    enctype: 'multipart/form-data',
                    processData: false,
                    contentType: false,
                    cache: false,
                    data: formData,
                    success: function (data) {
                        btn.removeAttr('disabled')
                        form.reset()

                        Fancybox.show([{
                            src: '#success_popup',
                            type: 'inline',
                            placeFocusBack: false,
                            trapFocus: false,
                            autoFocus: false,
                        }], {
                            dragToClose: false,
                        });

                        setTimeout(function () {
                            Fancybox.close()
                        }, 3000)
                    },
                    error: function () {
                        btn.removeAttr('disabled')
                    }
                })
            }

            forms.forEach(function(form) {
                validate(form, {submitFunction: onSubmit})
            })
        },

        '.project': function(sections){
            sections.forEach(function(section){
                let projectSwiper = section.querySelector('.swiper');

                new Swiper(projectSwiper, {
                    loop: true,
                    speed: 400,
                    spaceBetween: 17,
                    slidesPerView: 1,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }
                });

            })
        },

        '.system__images .swiper': function(sections){
            sections.forEach(function(section){
                let swiper = {
                    destroyed: true,
                }
                function initSwiper(){
                    if(!swiper.destroyed)
                        return
                    swiper = new Swiper(section, {
                        loop: true,
                        speed: 400,
                        spaceBetween: 15,
                        slidesPerView: 1,
                        pagination: {
                            el: ".swiper-pagination",
                            clickable: true,
                        },
                    });
                }
                function destroySwiper(){
                    if(swiper.destroyed)
                        return
                    swiper.destroy();

                }
                function checkSwiper(){
                    if(document.body.clientWidth > 768){
                        destroySwiper();
                    } else {
                        initSwiper();
                    }
                }

                window.addEventListener("resize", checkSwiper)
                checkSwiper();


            })
        },

        '.experience': function(sections){
            sections.forEach(function(section){
                let sectionSlider = section.querySelector('.swiper');
                let sliderNext = section.querySelector('.swiper-buttons__next');
                let sliderPrev = section.querySelector('.swiper-buttons__prev');
                new Swiper(sectionSlider, {
                    spaceBetween: 40,
                    slidesPerView: 1,
                    speed: 800,
                    loop: true,
                    navigation: {
                        nextEl: sliderNext,
                        prevEl: sliderPrev
                    },
                });
            })
        }



    }


    Object.keys(methods).forEach(selector => {
        if(document.querySelector(selector))
            methods[selector](document.querySelectorAll(selector))
    });


}




document.addEventListener('DOMContentLoaded', function(){
    blocks();
    anchors();

    Fancybox.bind('[data-fancybox]', {
        dragToClose: false,
    })
})


// Fancybox.show([{
//     src: '#modal_error',
//     type: 'inline',
//     placeFocusBack: false,
//     trapFocus: false,
//     autoFocus: false,
//   }], {
//     dragToClose: false,
//     on: {
//       "destroy": (event, fancybox, slide) => {
//         clearTimeout(closeTimeout)

//         if(activePopup){
//           openPopup(false, activePopup)
//         }
//       },
//     }
// });


